import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const Common_Popup_Flag = ({ show, onClose, Title, onYes, days, setDays }) => {

  const [calculatedDate, setCalculatedDate] = useState("");

  const calculateDateBasedOnDays = (days) => {
    // Get the current date
    const todayDate = new Date();
    console.log(todayDate);
    const newDate = new Date(todayDate);
    newDate.setDate(todayDate.getDate() + days);
    const formattedDate = `${newDate.getDate().toString().padStart(2, '0')}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate.getFullYear()}`;
    console.log(newDate);

    setCalculatedDate(formattedDate);

    // const currentDate = new Date();
    // console.log(currentDate.getMonth());
    // currentDate.setDate(currentDate.getDate() + days);
    // const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
    // console.log(formattedDate);
    // setCalculatedDate(formattedDate);
  }

  useEffect(() => {
    calculateDateBasedOnDays(days);
  },[days]);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        animation={false}
        backdrop="static"
        centered
        style={{    background: "rgba(0, 0, 0, 0.6)"}}

      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            {Title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {/* <div className="row mx-0 pl-2">
              <div className="col-12 btn_green_yellow p-3 rounded">
                <b>Note:</b> The date can be extended only up to 7 days.<br/>
                It cannot be extended beyond this limit.
              </div>
          </div>
          <hr/> */}

          <div className="row mx-0 my-2 pl-2">
              <div className="col-6">
                <label>Days</label>
                <input type="number" min={1} max={14} className="form-control" value={days}
                onChange={(e) => {
                  // calculateDateBasedOnDays(e.target.value);
                  setDays(parseInt(e.target.value));
                }}
                />
              </div>
              <div className="col-6">
                <label>Due Date</label>
                <input type="text" disabled className="form-control" value={calculatedDate}/>
              </div>
            </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-danger ms-1"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onClose}
          >
            No
          </button>
          <button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onYes}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Common_Popup_Flag;
